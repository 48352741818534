import { dmpconnectInitActionConstants } from 'dmpconnectjsapp-base/constants';
import { dmpconnectUserActionConstants } from '../constants';

const initialState = {};


export function userRights(state = initialState, action) {
  switch (action.type) {
    case dmpconnectInitActionConstants.DMPC_INIT_APPLICATION:
      return initialState;
    case dmpconnectUserActionConstants.SET_USER_RIGHTS:
      return action.accessRights;
    default:
      return state;
  }
}
